'use strict';

var app = {
	resizeSlide: function() {
		_.each( $( '.swiper-slide' ), function ( $slide ) {
			$slide = $( $slide );
			var description = $slide.find( '.description' );
			var img = $slide.find( 'img' );
			img.css( 'maxHeight', '100%' );
			var height = $slide.outerHeight() - description.outerHeight();
			img.css( 'maxHeight', height );
		} );
	}
};

$( document ).ready(function() {
	$( '#gnb a' ).on( 'mouseenter click', function( $event ) {
		var button = $( this );
		var parent = button.parent();
		var grandParent = parent.parent();
		var submenu = parent.find( '> .sub-menu' );

		grandParent.find( '.sub-menu' ).hide();
		if( submenu.length ) {
			$event.preventDefault();
			submenu.show();
		}
	} );

	$( '#site-header' ).on( 'mouseleave', function() {
		$( '#gnb .sub-menu' ).hide();
	} );

	$( '#m-menu' ).on( 'click', function() {
		if( $( '#gnb' ).hasClass( 'active' ) ) {
			$( '#gnb' ).removeClass( 'active' );
			$( '#gnb' ).hide();
			$( '#gnb .sub-menu' ).hide();
		}
		else {
			$( '#gnb' ).addClass( 'active' );
			$( '#gnb' ).show();
		}
	} );

	var uid = 0;
	_.each( $( '.swiper-container-wrapper' ), function( $element ) {
		$element = $( $element );
		$element.addClass( 'swiper-' + uid );

		new Swiper( '.swiper-' + uid + ' .swiper-container', {
			direction: 'horizontal',
			loop: false,
			slidePerView: 1,
			slidePerColumn: 1,
			pagination: '.swiper-' + uid + ' .swiper-pagination',
			paginationClickable: true
		} );

		uid++;
	} );

	app.resizeSlide();
} );

$( window ).on( 'resize', function() {
	app.resizeSlide();
} );